<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('update_register')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('update_register')" :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12 col-md-12">
                    <ValidationObserver ref="registerForm">
                        <div class="border px-5 pt-5 pb-2 mb-1 rounded">
                            <div class="row">
                                <div class="col-12 mb-2">
                                    <b-badge variant="primary" @click="changeStudentType" v-if="!foreignStudent">
                                        Öğrenci
                                    </b-badge>
                                    <b-badge variant="warning" @click="changeStudentType" v-if="foreignStudent">Yabancı
                                        Öğrenci
                                    </b-badge>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('semester')">
                                            <semesters-selectbox
                                                :validate-error="errors[0]"
                                                v-model="registerForm.semester">
                                            </semesters-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('registration_type')">
                                            <parameter-selectbox
                                                code="registration_types"
                                                :validate-error="errors[0]"
                                                v-model="registerForm.type">
                                            </parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="scholarshipRate" rules="required"
                                                        v-slot="{valid, errors}">
                                        <b-form-group :label="$t('scholarship_rate')">
                                            <scholarship-rate-selectbox
                                                :validateError="errors[0]"
                                                v-model="registerForm.scholarshipRate">
                                            </scholarship-rate-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="faculty" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('faculty')">
                                            <faculty-selectbox
                                                :validateError="errors[0]"
                                                v-model="registerForm.faculty">
                                            </faculty-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="department" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('department')">
                                            <department-selectbox
                                                :faculty_code="registerForm.faculty"
                                                :validateError="errors[0]"
                                                v-model="registerForm.department">
                                            </department-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="programCode" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('program')">
                                            <program-selectbox
                                                :faculty_code="registerForm.faculty"
                                                :department_code="registerForm.department"
                                                :validateError="errors[0]"
                                                v-model="registerForm.programCode">
                                            </program-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                            <div class="tabs-bottom-line"></div>
                            <b-tab active>
                                <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{
                                        foreignStudent == true ? $t('foreign_student_identity') : $t('identity')
                                    }}
                                </template>
                                <div class="row" v-show="!foreignStudent">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="tcNo"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('tc_no')">
                                                <b-form-input
                                                    v-mask="'###########'"
                                                    v-model="registerForm.tcNo"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="name"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input
                                                    v-model="registerForm.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="surname"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('surname')">
                                                <b-form-input
                                                    v-model="registerForm.surname"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="birthdate"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('date_of_birth')">
                                                <b-form-input
                                                    type="date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.birthdate"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="birthplace"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('birthplace')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.birthplace"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="gender"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('gender')">
                                                <gender-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="registerForm.gender">
                                                </gender-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="nationality"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('nationality')">
                                                <country-selectbox
                                                    valueType="code"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.nationality">
                                                </country-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="motherName"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mother_name')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.motherName"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="fatherName"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('father_name')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.fatherName"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="city" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('city_name')">
                                                <city-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.city">
                                                </city-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="district" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('district_name')">
                                                <district-outline-selectbox
                                                    :city_id="registerForm.city"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.district">
                                                </district-outline-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row" v-show="foreignStudent">
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="name"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input
                                                    type="text"
                                                    v-model="registerForm.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="surname"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('surname')">
                                                <b-form-input
                                                    v-model="registerForm.surname"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="gender"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('gender')">
                                                <gender-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="registerForm.gender">
                                                </gender-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="birthdate"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('date_of_birth')">
                                                <select-date v-model="registerForm.birthdate"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="birthplace"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('birthplace')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.birthplace"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="nationality"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('nationality')">
                                                <country-selectbox
                                                    valueType="code"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.nationality">
                                                </country-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="fatherName"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('father_name')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.fatherName"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="motherName"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mother_name')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.motherName"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="foreignDocumentType"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('foreign_document_type')">
                                                <parameter-selectbox
                                                    code="foreign_document_types"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.foreignDocumentType">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="foreignDocumentNumber"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('foreign_document_number')">
                                                <b-form-input
                                                    v-model="registerForm.foreignDocumentNumber"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="passportCountry"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('passport_country')">
                                                <country-selectbox
                                                    valueType="id"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.passportCountry">
                                                </country-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="passportNumber"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('passport_number')">
                                                <b-form-input
                                                    v-model="registerForm.passportNumber"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <b-form-group :label="$t('acceptance_letter_id')">
                                            <b-form-input
                                                v-model="registerForm.acceptance_letter_id"
                                                disabled>

                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="residencePermitNo" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('residence_permit_no')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.residencePermitNo"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="residencePermitStartDate" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('residence_permit_start_date')">
                                                <b-form-input
                                                    type="date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.residencePermitStartDate"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="residencePermitEndDate" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('residence_permit_end_date')">
                                                <b-form-input
                                                    type="date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.residencePermitEndDate"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-map-pin-line top-plus-2 mr-2"></i>{{ $t('address') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="contact_city_id" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('city')">
                                                <city-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.contact_city_id">
                                                </city-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="contact_district_id" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('district')">
                                                <district-outline-selectbox
                                                    :city_id="registerForm.contact_city_id"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.contact_district_id">
                                                </district-outline-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <ValidationProvider name="contact_address" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('address')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.contact_address"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-team-line top-plus-2 mr-2"></i>{{ $t('family_info') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mothersJob" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mother_job')">
                                                <parameter-selectbox
                                                    @input="handleJobInput($event, 'mother')"
                                                    code="jobs"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.mothersJob">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mothersEducation" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mothers_education')">
                                                <parameter-selectbox
                                                    code="education_statuses"
                                                    :validateError="errors[0]"
                                                    sort="rank"
                                                    v-model="registerForm.mothersEducation">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mothersGsm" rules="" v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('mother_gsm') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.mothersGsm"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.mothersJob == 'Diğer'">
                                        <ValidationProvider name="mothersJobOther" rules="required_if:mothersJob,Diğer"
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('job_other') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.mothersJobOther"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="fathersJob" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('father_job')">
                                                <parameter-selectbox
                                                    @input="handleJobInput($event, 'father')"
                                                    code="jobs"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.fathersJob">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="fathersEducation" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('fathers_education')">
                                                <parameter-selectbox
                                                    code="education_statuses"
                                                    :validateError="errors[0]"
                                                    sort="rank"
                                                    v-model="registerForm.fathersEducation">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="fathersGsm" rules="" v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('father_gsm') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.fathersGsm"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.fathersJob == 'Diğer'">
                                        <ValidationProvider name="fathersJobOther" rules="required_if:fathersJob,Diğer"
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('job_other') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.fathersJobOther"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-phone-line top-plus-2 mr-2"></i>{{ $t('contact') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mobileTel"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('gsm_no') }}</label>
                                                <b-form-input
                                                    v-mask="'(5##)-###-####'"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.mobileTel"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="homeTel" rules="" v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('home_tel') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.homeTel"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="email" rules="email" v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('email') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.email"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 mb-2 mt-4">
                                        <h6 class="text-uppercase mb-0">{{
                                                $t('emergency_contact_txt') | toUpperCase
                                            }}</h6>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergencyPersonRelation" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('degree_of_proximity') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergencyPersonRelation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergencyPersonName" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('name_surname') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergencyPersonName"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergencyPersonPhone" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('mobile_number') }}</label>
                                                <b-form-input
                                                    v-mask="'(5##)-###-####'"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergencyPersonPhone"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-pulse-line top-plus-2 mr-2"></i>{{ $t('health') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <ValidationProvider name="surgeryIllnessHistory" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('surgery_illness_history') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.surgeryIllnessHistory"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <ValidationProvider name="medicines" rules="" v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('medicines') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.medicines"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="typeOfDisability" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('disability_type')">
                                                <parameter-selectbox
                                                    code="disabled_types"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.typeOfDisability">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="disabilityRate" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('disability_rate')">
                                                <b-form-select
                                                    v-model="registerForm.disabilityRate">
                                                    <option value="">{{ $t('select') }}</option>
                                                    <option v-for="(row, rowindex) in 20" :key="rowindex"
                                                            :value="row*5">{{ row * 5 }}
                                                    </option>
                                                </b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-building-line top-plus-2 mr-2"></i>{{
                                        $t('education_info')
                                    }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="lastEducationStatus" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_education_status')">
                                                <parameter-selectbox v-model="registerForm.lastEducationStatus"
                                                                     code="education_statuses"
                                                                     sort="rank"
                                                                     :validateError="errors[0]"
                                                >
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div
                                        v-if="registerForm.lastEducationStatus==graduate || registerForm.lastEducationStatus==doctorate||registerForm.lastEducationStatus==undergraduate||registerForm.lastEducationStatus==associate_degree"
                                        class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="university_status" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('university_status')">
                                                <multiselect
                                                    v-model="university_status"
                                                    :options="university_type"
                                                    :multiple="false"
                                                    :select-label="''"
                                                    :selected-label="''"
                                                    :deselect-label="''"
                                                    :placeholder="$t('select')"
                                                    :searchable="true"></multiselect>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                                         class="mb-2"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.lastEducationStatus != 'high_school'">
                                        <ValidationProvider name="lastUniversity" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school')">
                                                <university-with-filter-selectbox
                                                    :validateError="errors[0]"
                                                    :is-turkey="university_status"
                                                    v-model="registerForm.lastUniversity"
                                                >
                                                </university-with-filter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.lastEducationStatus == 'high_school'">
                                        <ValidationProvider name="lastSchool" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school')">
                                                <school-selectbox
                                                    valueType="code"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.lastSchool">
                                                </school-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="lastSchoolRegistrationDate" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('last_school_registration_date') }}</label>
                                                <b-form-input
                                                    type="date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.lastSchoolRegistrationDate"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="lastSchoolDateOfGraduation" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('last_school_date_of_graduation') }}</label>
                                                <b-form-input
                                                    type="date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.lastSchoolDateOfGraduation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="lastSchoolDiplomaNumber" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('last_school_diploma_number') }}</label>
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.lastSchoolDiplomaNumber"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="lastSchoolDiplomaPoint" rules="double"
                                                            v-slot="{valid, errors}">
                                            <b-form-group>
                                                <label>{{ $t('last_school_diploma_point') }}</label>
                                                <b-form-input
                                                    v-decimal-numbers
                                                    max="100"
                                                    min="0"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.lastSchoolDiplomaPoint"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title><i
                                    class="ri-building-line top-plus-2 mr-2"></i>{{ $t('requests_info') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <ValidationProvider name="wantLeaveOfAbsence" rules="" v-slot="{valid, errors}">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                <label>{{ $t('want_leave_of_absence') }}</label>
                                                <b-form-radio-group v-model="registerForm.wantLeaveOfAbsence"
                                                                    :aria-describedby="ariaDescribedby">
                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12"
                                         v-if="registerForm.wantLeaveOfAbsence=='E'">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                                <ValidationProvider name="leaveOfAbsenceReason"
                                                                    :rules="registerForm.wantLeaveOfAbsence == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('leave_of_absence_reason')">
                                                        <parameter-selectbox
                                                            code="leave_of_absence_reasons"
                                                            :validateError="errors[0]"
                                                            v-model="registerForm.reasonForLeaveOfAbsence">
                                                        </parameter-selectbox>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                                <ValidationProvider name="leaveOfAbsenceSemesters"
                                                                    :rules="registerForm.wantLeaveOfAbsence == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('leave_of_absence_semesters')">
                                                        <b-form-select
                                                            v-model="registerForm.leaveOfAbsenceSemesters"
                                                            :options="leaveOfAbsenceSemesterOptions"></b-form-select>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="programLanguage == 'TR'">
                                        <ValidationProvider name="want_optional_prep" rules="" v-slot="{valid, errors}">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                <label>{{ $t('want_optional_prep') }} *</label>
                                                <b-form-radio-group v-model="registerForm.wantOptionalPrep"
                                                                    :aria-describedby="ariaDescribedby">
                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="programLanguage == 'EN'">
                                        <ValidationProvider name="hasEnglish" rules="" v-slot="{valid, errors}">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                <label>{{ $t('do_you_have_language_proficiency') }}</label>
                                                <b-form-radio-group
                                                    v-model="registerForm.hasEnglishProficiencyCertificate"
                                                    :aria-describedby="ariaDescribedby">
                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="row" v-if="registerForm.hasEnglishProficiencyCertificate == 'E'">
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                                <ValidationProvider name="englishProficiencyCertificateType"
                                                                    vid="englishProficiencyCertificateType"
                                                                    :rules="registerForm.hasEnglishProficiencyCertificate == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('cert_type')">
                                                        <parameter-selectbox
                                                            code="english_proficiency_certificate_types"
                                                            :validateError="errors[0]"
                                                            v-model="registerForm.englishProficiencyCertificateType">
                                                        </parameter-selectbox>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                                <ValidationProvider name="englishProficiencyExamDate"
                                                                    :rules="registerForm.hasEnglishProficiencyCertificate == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('exam_date')">
                                                        <b-form-input
                                                            type="date"
                                                            v-model="registerForm.englishProficiencyExamDate">
                                                        </b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                                <ValidationProvider name="englishProficiencyExamScore"
                                                                    :rules="registerForm.hasEnglishProficiencyCertificate == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('exam_score')">
                                                        <b-form-input
                                                            v-model="registerForm.englishProficiencyExamScore">
                                                        </b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                                 v-if="this.registerForm.englishProficiencyCertificateType == 'toefl'">
                                                <ValidationProvider name="toeflUsername"
                                                                    rules="required_if:englishProficiencyCertificateType,toefl"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group>
                                                        <label>{{ $t('toefl_username') }}</label>
                                                        <b-form-input
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            v-model="registerForm.toeflUsername"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                                 v-if="this.registerForm.englishProficiencyCertificateType == 'toefl'">
                                                <ValidationProvider name="toeflPassword"
                                                                    rules="required_if:englishProficiencyCertificateType,toefl"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group>
                                                        <label>{{ $t('toefl_password') }} *</label>
                                                        <b-form-input
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            v-model="registerForm.toeflPassword"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-building-line top-plus-2 mr-2"></i>{{ $t('documents') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div v-for="(item,index) in documents" v-bind:key="index">
                                            <b-row no-gutters class="file-upload-box mb-1">
                                                <b-col sm="12" md="5" lg="4">
                                                    <div class="filename d-flex" :class="{'active':item.uuid}">
                                                        <span class="flex-grow-1">{{
                                                                getLocaleText(item, 'name')
                                                            }}</span>
                                                    </div>
                                                </b-col>
                                                <b-col sm="12" md="5" lg="6">
                                                    <b-input-group>
                                                        <b-form-file
                                                            :ref="index"
                                                            v-model="files[index]"
                                                            :placeholder="item.uuid!=null?item.name:$t('select_file')+'...'"
                                                            @change="upload(index)"
                                                        >
                                                            <b-form-file id="file-small"></b-form-file>
                                                        </b-form-file>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary"
                                                                      class="btn-40 download"
                                                                      :v-b-popover.hover.right="$t('download')"
                                                                      v-if="item.uuid"
                                                                      @click="download(item.uuid)">
                                                                <i class="ri-download-2-line"></i>
                                                            </b-button>
                                                            <b-button variant="danger" class="btn-40"
                                                                      :v-b-popover.hover.right="$t('delete')"
                                                                      v-if="item.uuid"
                                                                      @click="remove(item.uuid)">
                                                                <i class="ri-delete-bin-7-line"></i>
                                                            </b-button>
                                                            <b-button variant="outline-secondary" class="btn-40"
                                                                      :v-b-popover.hover.right="$t('upload')"
                                                                      v-if="!item.uuid">
                                                                <i class="ri-add-fill"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-col>
                                                <b-col sm="12" md="2" lg="2">
                                                    <b-form-group v-if="!item.uuid">
                                                        <b-form-input
                                                            type="date"
                                                            v-model="dates[index]"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </ValidationObserver>
                </div>
                <div class="col-12 d-flex">
                    <b-button class="mr-2" variant="primary" @click="sendForm">{{ $t('save') | toUpperCase }}</b-button>
                    <b-button v-if="data && data.payment_status=='E' && data.registration_status=='H'" variant="success"
                              @click="register">{{ $t('complete_registration') | toUpperCase }}
                    </b-button>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import AppLayout from "@/layouts/AppLayout"
import {ValidationObserver, ValidationProvider} from "vee-validate";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import ScholarshipRateSelectbox from "@/components/interactive-fields/ScholarshipRateSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ProgramService from "@/services/ProgramService";
import RegistrationService from "@/services/RegistrationService";
import moment from "moment";
import SchoolSelectbox from "@/components/interactive-fields/SchoolSelectbox";
import UniversityWithFilterSelectbox from "@/components/interactive-fields/UniversityWithFilterSelectbox";
import StudentDocumentService from "@/services/StudentDocumentService";
import ParameterService from "@/services/ParameterService";
import SelectDate from "@/components/interactive-fields/SelectDate";

export default {
    components: {
        SelectDate,
        UniversityWithFilterSelectbox,
        AppLayout,
        ValidationObserver,
        ValidationProvider,
        GenderSelectbox,
        CountrySelectbox,
        CitySelectbox,
        DistrictOutlineSelectbox,
        ParameterSelectbox,
        SemestersSelectbox,
        ScholarshipRateSelectbox,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        Header,
        HeaderMobile,
        SchoolSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('update_register')
        }
    },
    data() {
        return {
            associate_degree: "associate_degree",
            doctorate: "doctorate",
            graduate: "graduate",
            undergraduate: "undergraduate",
            high_school: "high_school",
            university_status: null,
            foreignStudent: false,
            tabIndex: 1,
            programLanguage: null,
            university_type: ["Yurt İçi", "Yurt Dışı"],
            registerForm: {
                semester: null,
                type: null,
                scholarshipRate: null,
                faculty: null,
                department: null,
                programCode: null,
                tcNo: null,
                name: null,
                surname: null,
                birthdate: null,
                birthplace: null,
                gender: null,
                nationality: null,
                motherName: null,
                fatherName: null,
                city: null,
                district: null,
                foreignDocumentType: null,
                foreignDocumentNumber: null,
                passportCountry: null,
                passportNumber: null,
                residencePermitNo: null,
                residencePermitStartDate: null,
                residencePermitEndDate: null,
                contact_city_id: null,
                contact_district_id: null,
                contactAddress: null,
                contactcontact_city_id: null,
                contactcontact_district_id: null,
                address: null,
                mothersJob: null,
                mothersJobOther: null,
                mothersGsm: null,
                mothersEducation: null,
                fathersEducation: null,
                fathersJob: null,
                fathersJobOther: null,
                fathersGsm: null,
                homeTel: null,
                mobileTel: null,
                email: null,
                emergencyPersonRelation: null,
                emergencyPersonName: null,
                emergencyPersonPhone: null,
                surgeryIllnessHistory: null,
                medicines: null,
                typeOfDisability: null,
                disabilityRate: null,
                lastEducationStatus: null,
                lastSchoolRegistrationDate: null,
                lastUniversity: null,
                lastSchool: null,
                lastSchoolDateOfGraduation: null,
                lastSchoolDiplomaNumber: null,
                lastSchoolDiplomaPoint: null,
                wantLeaveOfAbsence: null,
                reasonForLeaveOfAbsence: null,
                leaveOfAbsenceSemesters: null,
                wantOptionalPrep: null,
                hasEnglishProficiencyCertificate: null,
                englishProficiencyCertificateType: null,
                englishProficiencyExamDate: null,
                englishProficiencyExamScore: null,
                toeflUsername: null,
                toeflPassword: null,
                missingDocuments: null,
                acceptance_letter_id:null
            },

            leaveOfAbsenceSemesterOptions: [
                {value: null, text: this.$t('select')},
                {value: 1, text: this.$t('fall')},
                {value: 2, text: this.$t('spring')},
                {value: 3, text: this.$t('fall_and_spring')}
            ],

            documents: null,
            data: null,
            files: [],
            dates: [],

            upperAlphaMask: {
                mask: 'UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU',
                tokens: {
                    'U': {
                        pattern: /[A-Za-z ÇĞİÖŞÜçğıöşü]/,
                        transform: function (v) {
                            v = v.replaceAll('i', 'İ');
                            return v.toUpper();
                        }
                    }
                }
            },

            parameterJobs: null
        }
    },
    methods: {
        nameWithLang({name}) {
            return `${name}`
        },

        getData() {
            if (this.$route.params.id) {
                RegistrationService.show(this.$route.params.id).then(response => {
                    let data = response.data.data
                    this.data = data
                    let parsedMotherJob = this.parseJobFields(data.mothers_job)
                    let parsedFatherJob = this.parseJobFields(data.fathers_job)

                    this.registerForm.semester = data.semester_id
                    this.registerForm.type = data.type
                    this.registerForm.scholarshipRate = data.scholarship_rate
                    this.registerForm.faculty = data.faculty_code
                    this.registerForm.department = data.department_code
                    this.registerForm.programCode = data.program_code
                    this.registerForm.tcNo = data.national_id
                    this.registerForm.name = data.name
                    this.registerForm.surname = data.surname
                    this.registerForm.birthdate = data.birthdate
                    this.registerForm.birthplace = data.birthplace
                    this.registerForm.gender = data.gender
                    this.registerForm.nationality = data.nationality_code
                    this.registerForm.foreignDocumentType = data.foreign_document_type
                    this.registerForm.foreignDocumentNumber = data.foreign_document_number
                    this.registerForm.motherName = data.mother_name
                    this.registerForm.fatherName = data.father_name
                    this.registerForm.city = data.city_id
                    this.registerForm.district = data.district_id
                    this.registerForm.passportCountry = data.passport_country_id
                    this.registerForm.passportNumber = data.passport_number
                    this.registerForm.residencePermitNo = data.residence_permit_no
                    this.registerForm.residencePermitStartDate = data.residence_permit_start_date
                    this.registerForm.residencePermitEndDate = data.residence_permit_end_date
                    this.registerForm.contact_city_id = data.contact_city_id
                    this.registerForm.contact_district_id = data.contact_district_id
                    this.registerForm.contactAddress = data.contact_address
                    this.registerForm.contactcontact_city_id = data.contact_city_id
                    this.registerForm.contactcontact_district_id = data.contact_district_id
                    this.registerForm.contact_address = data.contact_address
                    this.registerForm.mothersJob = parsedMotherJob.job
                    this.registerForm.mothersJobOther = parsedMotherJob.other
                    this.registerForm.mothersGsm = data.mothers_phone
                    this.registerForm.mothersEducation = data.mothers_education
                    this.registerForm.fathersEducation = data.fathers_education
                    this.registerForm.fathersJob = parsedFatherJob.job
                    this.registerForm.fathersJobOther = parsedFatherJob.other
                    this.registerForm.fathersGsm = data.fathers_phone
                    this.registerForm.homeTel = data.home_tel
                    this.registerForm.mobileTel = data.mobile_tel
                    this.registerForm.email = data.email
                    this.registerForm.emergencyPersonRelation = data.emergency_person_relation
                    this.registerForm.emergencyPersonName = data.emergency_person_name
                    this.registerForm.emergencyPersonPhone = data.emergency_person_phone
                    this.registerForm.surgeryIllnessHistory = data.surgery_illness_history
                    this.registerForm.medicines = data.medicines
                    this.registerForm.typeOfDisability = data.type_of_disability
                    this.registerForm.disabilityRate = data.disability_rate
                    this.registerForm.lastEducationStatus = data.last_education_status
                    this.registerForm.lastSchoolRegistrationDate = data.last_school_registration_date
                    this.university_status = data.last_university_nationality == 'abroad' ? 'Yurt Dışı' : 'Yurt İçi'
                    this.registerForm.lastUniversity = data.last_university_id
                    this.registerForm.lastSchool = data.school_code
                    this.registerForm.lastSchoolDateOfGraduation = data.last_school_date_of_graduation
                    this.registerForm.lastSchoolDiplomaNumber = data.last_school_diploma_number
                    this.registerForm.lastSchoolDiplomaPoint = data.last_school_diploma_point
                    this.registerForm.wantLeaveOfAbsence = data.want_leave_of_absence
                    this.registerForm.reasonForLeaveOfAbsence = data.reason_for_leave_of_absence
                    this.registerForm.leaveOfAbsenceSemesters = data.leave_of_absence_semesters
                    this.registerForm.wantOptionalPrep = data.want_optional_prep
                    this.registerForm.hasEnglishProficiencyCertificate = data.has_english_proficiency_certificate
                    this.registerForm.englishProficiencyCertificateType = data.english_proficiency_certificate_type
                    this.registerForm.englishProficiencyExamDate = data.english_proficiency_exam_date
                    this.registerForm.englishProficiencyExamScore = data.english_proficiency_exam_score
                    this.registerForm.toeflUsername = data.toefl_username
                    this.registerForm.toeflPassword = data.toefl_password
                    this.registerForm.missingDocuments = data.missing_documents == null ? [] : data.missing_documents
                    this.registerForm.acceptance_letter_id=data.acceptance_letter_id
                })
            } else {
                this.$router.push('/404')
            }
        },

        getDocuments() {
            RegistrationService.getDocuments(this.$route.params.id)
                .then(response => {
                    this.documents = response.data.data
                })
        },

        async sendForm() {
            const isValid = await this.$refs.registerForm.validate();
            if (isValid) {

                const missingDocuments = []
                this.documents.forEach((item, index) => {
                    if (item.uuid == null && this.dates[index]) {
                        missingDocuments.push({
                            document_type_id: item.id,
                            document_due_date: this.dates[index]
                        })
                    }
                })

                let formData1 = {
                    'semester_id': this.registerForm.semester,
                    'type': this.registerForm.type,
                    'program_code': this.registerForm.programCode,
                    'scholarship_rate': this.registerForm.scholarshipRate,
                    'name': this.registerForm.name,
                    'surname': this.registerForm.surname,
                    'father_name': this.registerForm.fatherName,
                    'mother_name': this.registerForm.motherName,
                    'gender': this.registerForm.gender,
                    'contact_address': this.registerForm.contact_address,
                    'mobile_tel': this.registerForm.mobileTel
                }
                let formData2 = {}
                if (this.foreignStudent == true) {
                    formData2 = {
                        'passport_number': this.registerForm.passportNumber,
                        'passport_country_id': this.registerForm.passportCountry,
                        'nationality_code': this.registerForm.nationality,
                        'foreign_document_type': this.registerForm.foreignDocumentType,
                        'foreign_document_number': this.registerForm.foreignDocumentNumber,
                        ...formData1
                    }
                } else {
                    formData2 = {
                        'national_id': this.registerForm.tcNo,
                        'mother_name': this.registerForm.motherName,
                        'nationality_code': this.registerForm.nationality,
                        'birthdate': this.registerForm.birthdate,
                        'birthplace': this.registerForm.birthplace,
                        ...formData1
                    }
                }

                let formData = {...formData2}

                this.registerForm.city != null ? formData.city_id = this.registerForm.city : false
                this.registerForm.district != null ? formData.district_id = this.registerForm.district : false
                this.registerForm.contact_city_id != null ? formData.contact_city_id = this.registerForm.contact_city_id : false
                this.registerForm.contact_district_id != null ? formData.contact_district_id = this.registerForm.contact_district_id : false
                this.registerForm.contactAddress != null ? formData.contact_address = this.registerForm.contactAddress : false
                this.registerForm.contactcontact_city_id != null ? formData.contact_city_id = this.registerForm.contactcontact_city_id : false
                this.registerForm.contactcontact_district_id != null ? formData.contact_district_id = this.registerForm.contactcontact_district_id : false
                this.registerForm.homeTel != null ? formData.home_tel = this.registerForm.homeTel : false
                this.registerForm.email != null ? formData.email = this.registerForm.email : false
                this.registerForm.hasEnglishProficiencyCertificate != null ? formData.has_english_proficiency_certificate = this.registerForm.hasEnglishProficiencyCertificate : false
                this.registerForm.wantOptionalPrep != null ? formData.want_optional_prep = this.registerForm.wantOptionalPrep : false
                this.registerForm.wantLeaveOfAbsence != null ? formData.want_leave_of_absence = this.registerForm.wantLeaveOfAbsence : false
                this.registerForm.residencePermitNo != null ? formData.residence_permit_no = this.registerForm.residencePermitNo : false
                this.registerForm.residencePermitStartDate != null ? formData.residence_permit_start_date = this.registerForm.residencePermitStartDate : false
                this.registerForm.residencePermitEndDate != null ? formData.residence_permit_end_date = this.registerForm.residencePermitEndDate : false
                this.registerForm.mothersJob != null && this.registerForm.mothersJob != '' ? formData.mothers_job = this.registerForm.mothersJob : null
                this.registerForm.mothersJobOther != null && this.registerForm.mothersJobOther != '' ? formData.mothers_job = this.registerForm.mothersJobOther : null
                this.registerForm.mothersEducation != null ? formData.mothers_education = this.registerForm.mothersEducation : false
                this.registerForm.fathersJob != null && this.registerForm.fathersJob != '' ? formData.fathers_job = this.registerForm.fathersJob : false
                this.registerForm.fathersJobOther != null && this.registerForm.fathersJobOther != '' ? formData.fathers_job = this.registerForm.fathersJobOther : false
                this.registerForm.fathersEducation != null ? formData.fathers_education = this.registerForm.fathersEducation : false
                this.registerForm.mothersGsm != null ? formData.mothers_phone = this.registerForm.mothersGsm : false
                this.registerForm.fathersGsm != null ? formData.fathers_phone = this.registerForm.fathersGsm : false
                this.registerForm.lastEducationStatus != null ? formData.last_education_status = this.registerForm.lastEducationStatus : false
                this.registerForm.lastUniversity != null ? formData.last_university_id = this.registerForm.lastUniversity : false
                this.registerForm.lastSchool != null ? formData.school_code = this.registerForm.lastSchool : false
                this.registerForm.lastSchoolRegistrationDate != null ? formData.last_school_registration_date = moment(this.registerForm.lastSchoolRegistrationDate).format('YYYY-MM-DD') : false
                this.registerForm.lastSchoolDateOfGraduation != null ? formData.last_school_date_of_graduation = moment(this.registerForm.lastSchoolDateOfGraduation).format('YYYY-MM-DD') : false
                this.registerForm.lastSchoolDiplomaNumber != null ? formData.last_school_diploma_number = this.registerForm.lastSchoolDiplomaNumber : false
                this.registerForm.lastSchoolDiplomaPoint != null ? formData.last_school_diploma_point = this.registerForm.lastSchoolDiplomaPoint : false
                this.registerForm.emergencyPersonRelation != null ? formData.emergency_person_relation = this.registerForm.emergencyPersonRelation : false
                this.registerForm.emergencyPersonName != null ? formData.emergency_person_name = this.registerForm.emergencyPersonName : false
                this.registerForm.emergencyPersonPhone != null ? formData.emergency_person_phone = this.registerForm.emergencyPersonPhone : false
                this.registerForm.surgeryIllnessHistory != null ? formData.surgery_illness_history = this.registerForm.surgeryIllnessHistory : false
                this.registerForm.medicines != null ? formData.medicines = this.registerForm.medicines : false
                this.registerForm.typeOfDisability != null ? formData.type_of_disability = this.registerForm.typeOfDisability : false
                this.registerForm.disabilityRate != null ? formData.disability_rate = this.registerForm.disabilityRate : false
                this.registerForm.englishProficiencyCertificateType != null ? formData.english_proficiency_certificate_type = this.registerForm.englishProficiencyCertificateType : false
                this.registerForm.englishProficiencyExamDate != null ? formData.english_proficiency_exam_date = this.registerForm.englishProficiencyExamDate : false
                this.registerForm.englishProficiencyExamScore != null ? formData.english_proficiency_exam_score = this.registerForm.englishProficiencyExamScore : false
                this.registerForm.toeflUsername != null ? formData.toefl_username = this.registerForm.toeflUsername : false
                this.registerForm.toeflPassword != null ? formData.toefl_password = this.registerForm.toeflPassword : false
                this.registerForm.leaveOfAbsenceSemesters != null ? formData.leave_of_absence_semesters = this.registerForm.leaveOfAbsenceSemesters : false
                this.registerForm.reasonForLeaveOfAbsence != null ? formData.reason_for_leave_of_absence = this.registerForm.reasonForLeaveOfAbsence : false
                missingDocuments.length > 0 ? formData.missing_documents = missingDocuments : false

                formData.contact_address = this.registerForm.contact_address
                formData.contact_city_id = this.registerForm.contact_city_id
                formData.contact_district_id = this.registerForm.contact_district_id

                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('prep_classes_update_confirm_text'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {
                    if (response.isConfirmed == true) {

                        RegistrationService.update(this.$route.params.id, formData).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                        }).catch(e => {
                            if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                                if (e.data.message) {
                                    this.$toast.error(this.$t('api.' + e.data.message));
                                }
                            } else {
                                this.$toast.error(this.$t('fields_are_reqired'));
                                if (e.data.errors.semester_id) {
                                    this.$refs.registerForm.errors.semester.push(e.data.errors.semester_id[0]);
                                }
                                if (e.data.errors.type) {
                                    this.$refs.registerForm.errors.type.push(e.data.errors.type[0]);
                                }
                                if (e.data.errors.program_code) {
                                    this.$refs.registerForm.errors.programCode.push(e.data.errors.program_code[0]);
                                }
                                if (e.data.errors.scholarship_rate) {
                                    this.$refs.registerForm.errors.scholarshipRate.push(e.data.errors.scholarship_rate[0]);
                                }
                                if (e.data.errors.name) {
                                    this.$refs.registerForm.errors.name.push(e.data.errors.name[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.surname) {
                                    this.$refs.registerForm.errors.surname.push(e.data.errors.surname[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.father_name) {
                                    this.$refs.registerForm.errors.fatherName.push(e.data.errors.father_name[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.mother_name) {
                                    this.$refs.registerForm.errors.motherName.push(e.data.errors.mother_name[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.nationality_code) {
                                    this.$refs.registerForm.errors.nationality.push(e.data.errors.nationality_code[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.passport_number) {
                                    this.$refs.registerForm.errors.passportNumber.push(e.data.errors.passport_number[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.gender) {
                                    this.$refs.registerForm.errors.gender.push(e.data.errors.gender[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.contact_address) {
                                    this.$refs.registerForm.errors.contact_address.push(e.data.errors.contact_address[0]);
                                    this.tabIndex = 1
                                }
                                if (e.data.errors.mobile_tel) {
                                    this.$refs.registerForm.errors.mobileTel.push(e.data.errors.mobile_tel[0]);
                                    this.tabIndex = 3
                                }
                                if (e.data.errors.passport_country_id) {
                                    this.$refs.registerForm.errors.passportCountry.push(e.data.errors.passport_country_id[0]);
                                }
                                if (e.data.errors.national_id) {
                                    this.$refs.registerForm.errors.tcNo.push(e.data.errors.national_id[0]);
                                }
                                if (e.data.errors.national_id) {
                                    this.$refs.registerForm.errors.tcNo.push(e.data.errors.national_id[0]);
                                }
                                if (e.data.errors.birthdate) {
                                    this.$refs.registerForm.errors.birthdate.push(e.data.errors.birthdate[0]);
                                }
                                if (e.data.errors.birthplace) {
                                    this.$refs.registerForm.errors.birthplace.push(e.data.errors.birthplace[0]);
                                }
                                if (e.data.errors.city_id) {
                                    this.$refs.registerForm.errors.city.push(e.data.errors.city_id[0]);
                                }
                                if (e.data.errors.district_id) {
                                    this.$refs.registerForm.errors.district.push(e.data.errors.district_id[0]);
                                }
                                if (e.data.errors.contact_city_id) {
                                    this.$refs.registerForm.errors.contact_city_id.push(e.data.errors.contact_city_id[0]);
                                }
                                if (e.data.errors.contact_district_id) {
                                    this.$refs.registerForm.errors.contact_district_id.push(e.data.errors.contact_district_id[0]);
                                }
                                if (e.data.errors.contact_address) {
                                    this.$refs.registerForm.errors.contactAddress.push(e.data.errors.contact_address[0]);
                                }
                                if (e.data.errors.contact_city_id) {
                                    this.$refs.registerForm.errors.contactcontact_city_id.push(e.data.errors.contact_city_id[0]);
                                }
                                if (e.data.errors.contact_district_id) {
                                    this.$refs.registerForm.errors.contactcontact_district_id.push(e.data.errors.contact_district_id[0]);
                                }
                                if (e.data.errors.home_tel) {
                                    this.$refs.registerForm.errors.homeTel.push(e.data.errors.home_tel[0]);
                                }
                                if (e.data.errors.email) {
                                    this.$refs.registerForm.errors.email.push(e.data.errors.email[0]);
                                }
                                if (e.data.errors.has_english_proficiency_certificate) {
                                    this.$refs.registerForm.errors.hasEnglishProficiencyCertificate.push(e.data.errors.has_english_proficiency_certificate[0]);
                                }
                                if (e.data.errors.want_optional_prep) {
                                    this.$refs.registerForm.errors.wantOptionalPrep.push(e.data.errors.want_optional_prep[0]);
                                }
                                if (e.data.errors.want_leave_of_absence) {
                                    this.$refs.registerForm.errors.wantLeaveOfAbsence.push(e.data.errors.want_leave_of_absence[0]);
                                }
                                if (e.data.errors.passport_country_id) {
                                    this.$refs.registerForm.errors.passportCountry.push(e.data.errors.passport_country_id[0]);
                                }
                                if (e.data.errors.residence_permit_no) {
                                    this.$refs.registerForm.errors.residencePermitNo.push(e.data.errors.residence_permit_no[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.residence_permit_start_date) {
                                    this.$refs.registerForm.errors.residencePermitStartDate.push(e.data.errors.residence_permit_start_date[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.residence_permit_end_date) {
                                    this.$refs.registerForm.errors.residencePermitEndDate.push(e.data.errors.residence_permit_end_date[0]);
                                    this.tabIndex = 0
                                }
                                if (e.data.errors.fathers_education) {
                                    this.$refs.registerForm.errors.fathersEducation.push(e.data.errors.fathers_education[0]);
                                }
                                if (e.data.errors.mothers_education) {
                                    this.$refs.registerForm.errors.mothersEducation.push(e.data.errors.mothers_education[0]);
                                }
                                if (e.data.errors.mothers_job) {
                                    this.$refs.registerForm.errors.mothersJob.push(e.data.errors.mothers_job[0]);
                                }
                                if (e.data.errors.fathers_job) {
                                    this.$refs.registerForm.errors.fathersJob.push(e.data.errors.fathers_job[0]);
                                }
                                if (e.data.errors.mothers_phone) {
                                    this.$refs.registerForm.errors.mothersGsm.push(e.data.errors.mothers_phone[0]);
                                }
                                if (e.data.errors.fathers_phone) {
                                    this.$refs.registerForm.errors.fathersGsm.push(e.data.errors.fathers_phone[0]);
                                }
                                if (e.data.errors.last_education_status) {
                                    this.$refs.registerForm.errors.lastEducationStatus.push(e.data.errors.last_education_status[0]);
                                }
                                if (e.data.errors.last_university_id) {
                                    this.$refs.registerForm.errors.lastUniversity.push(e.data.errors.last_university_id[0]);
                                }
                                if (e.data.errors.last_school_registration_date) {
                                    this.$refs.registerForm.errors.lastSchoolRegistrationDate.push(e.data.errors.last_school_registration_date[0]);
                                }
                                if (e.data.errors.last_school_date_of_graduation) {
                                    this.$refs.registerForm.errors.lastSchoolDateOfGraduation.push(e.data.errors.last_school_date_of_graduation[0]);
                                }
                                if (e.data.errors.last_school_diploma_number) {
                                    this.$refs.registerForm.errors.lastSchoolDiplomaNumber.push(e.data.errors.last_school_diploma_number[0]);
                                }
                                if (e.data.errors.last_school_diploma_point) {
                                    this.$refs.registerForm.errors.lastSchoolDiplomaPoint.push(e.data.errors.last_school_diploma_point[0]);
                                }
                                if (e.data.errors.emergency_person_relation) {
                                    this.$refs.registerForm.errors.emergencyPersonRelation.push(e.data.errors.emergency_person_relation[0]);
                                }
                                if (e.data.errors.emergency_person_name) {
                                    this.$refs.registerForm.errors.emergencyPersonName.push(e.data.errors.emergency_person_name[0]);
                                }
                                if (e.data.errors.emergency_person_phone) {
                                    this.$refs.registerForm.errors.emergencyPersonPhone.push(e.data.errors.emergency_person_phone[0]);
                                }
                                if (e.data.errors.surgery_illness_history) {
                                    this.$refs.registerForm.errors.surgeryIllnessHistory.push(e.data.errors.surgery_illness_history[0]);
                                }
                                if (e.data.errors.medicines) {
                                    this.$refs.registerForm.errors.medicines.push(e.data.errors.medicines[0]);
                                }
                                if (e.data.errors.type_of_disability) {
                                    this.$refs.registerForm.errors.typeOfDisability.push(e.data.errors.type_of_disability[0]);
                                }
                                if (e.data.errors.disability_rate) {
                                    this.$refs.registerForm.errors.disabilityRate.push(e.data.errors.disabilityRate[0]);
                                }
                                if (e.data.errors.english_proficiency_certificate_type) {
                                    this.$refs.registerForm.errors.englishProficiencyCertificateType.push(e.data.errors.english_proficiency_certificate_type[0]);
                                }
                                if (e.data.errors.english_proficiency_exam_date) {
                                    this.$refs.registerForm.errors.englishProficiencyExamDate.push(e.data.errors.english_proficiency_exam_date[0]);
                                }
                                if (e.data.errors.english_proficiency_exam_score) {
                                    this.$refs.registerForm.errors.englishProficiencyExamScore.push(e.data.errors.english_proficiency_exam_score[0]);
                                }
                                if (e.data.errors.toefl_username) {
                                    this.$refs.registerForm.errors.toeflUsername.push(e.data.errors.toefl_username[0]);
                                }
                                if (e.data.errors.toefl_password) {
                                    this.$refs.registerForm.errors.toeflPassword.push(e.data.errors.toefl_password[0]);
                                }
                                if (e.data.errors.leave_of_absence_semesters) {
                                    this.$refs.registerForm.errors.leaveOfAbsenceSemesters.push(e.data.errors.leave_of_absence_semesters[0]);
                                }
                                if (e.data.errors.reason_for_leave_of_absence) {
                                    this.$refs.registerForm.errors.reasonForLeaveOfAbsence.push(e.data.errors.reason_for_leave_of_absence[0]);
                                }
                            }
                        })

                    }
                })
            } else {

                if (this.foreignStudent == true) {
                    if (this.registerForm.name == null || this.registerForm.name == '' ||
                        this.registerForm.surname == null || this.registerForm.surname == '' || this.registerForm.gender == null || this.registerForm.gender == ''
                        || this.registerForm.passportCountry == null || this.registerForm.passportCountry == '' ||
                        this.registerForm.passportNumber == null || this.registerForm.passportNumber == '' || this.registerForm.fatherName == null || this.registerForm.fatherName == '') {
                        this.tabIndex = 0
                    } else if (this.registerForm.contact_address == null || this.registerForm.contact_address == '') {
                        this.tabIndex = 1
                    } else if (this.registerForm.mobileTel == null || this.registerForm.mobileTel == '') {
                        this.tabIndex = 3
                    }
                } else {
                    if (this.registerForm.tcNo == null || this.registerForm.tcNo == '' || this.registerForm.name == null || this.registerForm.name == '' ||
                        this.registerForm.surname == null || this.registerForm.surname == '' || this.registerForm.birthplace == null || this.registerForm.birthplace == ''
                        || this.registerForm.birthdate == null || this.registerForm.birthdate == '' || this.registerForm.nationality == null || this.registerForm.nationality == '' ||
                        this.registerForm.motherName == null || this.registerForm.motherName == '') {
                        this.tabIndex = 0
                    } else if (this.registerForm.contact_address == null || this.registerForm.contact_address == '') {
                        this.tabIndex = 1
                    } else if (this.registerForm.mobileTel == null || this.registerForm.mobileTel == '') {
                        this.tabIndex = 3
                    }
                }

            }
        },

        register() {
            this.$swal.fire({
                text: this.$t('do_you_want_to_complete_registration'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        RegistrationService.register(this.$route.params.id)
                            .then(response => {
                                let student_program_id = response.data.data.student_program.id
                                let formData = {
                                    language: this.$i18n.locale.toUpper()
                                }
                                StudentDocumentService.downloadRegistrationForm(student_program_id, formData).then(response => {
                                    this._downloadFile(response, this.$t('student_registration_form') + '.pdf')
                                })
                                this.$toast.success(this.$t('api.' + response.data.message));
                            }).catch(e => {
                            this.showErrors(e);
                        })
                    }
                })
        },

        changeStudentType() {
            if (this.foreignStudent == true) {
                this.foreignStudent = false
            } else {
                this.foreignStudent = true

            }
        },

        isInterStudent(value) {
            if (value == 203 || value == 232 || value == 233 || value == 234 || value == 235 || value == 215 || value == 220) {
                this.foreignStudent = true
            } else {
                this.foreignStudent = false
            }
        },

        setProgramLanguage(programCode) {
            ProgramService.get(programCode).then(response => {
                this.programLanguage = response.data.data.language
            })
        },

        upload(index) {
            if (this.checkPermission("registration_document")) {
                setTimeout(() => {
                    if (this.documents[index]) {
                        let formData = new FormData()
                        formData.append('document_id', this.documents[index].id)
                        formData.append('file', this.files[index])
                        RegistrationService.uploadDocuments(this.$route.params.id, formData).then(response => {
                            this.getDocuments()
                            this.$toast.success(this.$t('api.' + response.data.message));
                        }).catch(e => {
                            if (e.status == 422) {
                                this.$toast.error(e.data.errors.file[0]);
                            }
                            if (e.status == 406) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        }).finally(() => {
                            this.files[index] = null;
                        });
                    }
                }, 2000)
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        download(uuid) {
            if (this.checkPermission("registration_documentdownload")) {
                RegistrationService.downloadDocuments({uuid: uuid}).then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']})
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                }).catch(e => {
                    if (e.status == 422) {
                        this.$toast.error(e.data.errors.file[0]);
                    }
                    if (e.status == 406) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        remove(uuid) {
            if (this.checkPermission("registration_documentdelete")) {
                RegistrationService.deleteDocuments(uuid).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.getDocuments()
                }).catch(e => {
                    if (e.status == 422) {
                        this.$toast.error(e.data.errors.file[0]);
                    }
                    if (e.status == 406) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        setMissingDocuments() {
            setTimeout(() => {
                this.documents.forEach((item, index) => {
                    this.registerForm.missingDocuments.forEach((itm) => {
                        if (item.id == itm.document_type_id) {
                            this.dates[index] = itm.document_due_date
                        }
                    })
                })
            }, 2000)

        },

        getJobs() {
            ParameterService.getItems('jobs').then(response => {
                this.parameterJobs = response.data.data.items
            })
        },

        parseJobFields(jobData) {
            let result = {
                job: null,
                other: null
            }

            if (jobData != null) {
                this.parameterJobs.forEach(itm => {
                    if (itm.code == jobData) {
                        result.job = itm.code
                    }
                })

                if (result.job == null) {
                    result.job = 'Diğer'
                    result.other = jobData
                }
            }

            return result
        },

        handleJobInput(data, which) {
            if (data == 'Diğer') {
                if (which == 'mother') {
                    this.registerForm.mothersJobOther = null
                } else if (which == 'father') {
                    this.registerForm.fathersJobOther = null
                }
            }
        },
    },

    watch: {
        'registerForm.programCode': {
            handler: function (val) {
                this.setProgramLanguage(val)
            }
        },

        'registerForm.type': {
            handler: function (val) {
                this.isInterStudent(val)
            }
        },
    },

    created() {
        this.getJobs()
        this.getData()
        this.getDocuments()
        this.setMissingDocuments()

    }
};
</script>

